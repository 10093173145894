<nav class="navbar sticky-top navbar-light">
  <div class="container-fluid">
    <img src="../assets/logo.PNG" id="logo">
    <img id="btn-home" src="assets/icon-home.png" (click)="validarRota()">
    <button class="btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
      aria-controls="offcanvasRight"><img src="assets/icon-menu.png"></button>
  </div>

</nav>

<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
  <div class="offcanvas-header">
    <h5 id="offcanvasRightLabel">Menu</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body">
    <ul id="lista-menu">
      <li id="list-menu-item" *ngIf="siteUnico == false" (click)="avaliaRota('busca-empresa')" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"><img id="icon-item-list" src="assets/shared.png"> Buscar empresas</li>
      <li id="list-menu-item" (click)="navigateCarrinho()" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"><img id="icon-item-list" src="assets/icon-carrinho.png"> Carrinho</li>
      <li id="list-menu-item" (click)="desejos()" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"><img id="icon-item-list" src="assets/icon-desejos.png"> Desejos</li>
      <li id="list-menu-item" (click)="pedido()" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
        aria-controls="offcanvasRight"><img id="icon-item-list" src="assets/icon-pedidos.png"> Pedidos</li>
        <li id="list-menu-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" *ngIf="!usuario" (click)="login()"
        aria-controls="offcanvasRight"><img id="icon-item-list" src="assets/icon-login.png"> Login</li>
        <li id="list-menu-item" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" *ngIf="usuario" (click)="logout()"
        aria-controls="offcanvasRight"><img id="icon-item-list" src="assets/icon-logout.png"> Sair</li>
    </ul>
  </div>
</div>
<div class="container">
  <router-outlet #outlet="outlet"></router-outlet>
</div>
<nav class="navbar fixed-bottom navbar-light bg-light" id="rodape">
  <div class="container-fluid" id="text">
    <a id="text-rodape" class="navbar-brand" href="http://www.thorinformatica.com.br/">www.thorinformatica.com.br</a>
  </div>
</nav>

<div class="toast align-items-center text-white bg-danger border-0" role="alert" aria-live="assertive" id="message"
    aria-atomic="true">
    <div class="d-flex">
        <div class="toast-body">
            {{ mensagemView }}
        </div>
    </div>
</div>