import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EmpresaV2Result } from 'src/app/models/empresasV2Result';
import { ProdutosFirstPage } from 'src/app/models/produtosFirstPage';
import { environment } from 'src/environments/environment';
import { GenericResult } from '../../models/genericResult';
import { HeadersService } from '../headers.service';

@Injectable({
  providedIn: 'root'
})
export class EmpresaService {

  url = environment.url

  httpOptions = {
    headers: new HttpHeaders()
  };

  uf: string
  atividade: string;

  constructor(private http: HttpClient, private headers: HeadersService) {
    this.httpOptions.headers = this.headers.httpOptions.headers
    this.httpOptions.headers = this.headers.httpOptions.headers.append('dwaccesstag', 'VEhPUg==')
  }

  getAllEmpresasEstado(uf: string, atividade: string): Observable<GenericResult<EmpresaV2Result[]>> {
    this.uf = uf
    this.atividade = atividade
    return this.http.get<GenericResult<EmpresaV2Result[]>>(`${this.url}/empresas?uf=${uf}&atividade=${atividade}`, this.httpOptions);
  }

  getAllEmpresasFirstPage(): Observable<GenericResult<ProdutosFirstPage[]>> {
    return this.http.get<GenericResult<ProdutosFirstPage[]>>(`${this.url}/firstpage`, this.httpOptions);
  }

  getEmpresaDescMT(desc: any) {
    return this.http.get<any[]>(`${this.url}/empresas?uf=MT&?descr=${desc}`, this.httpOptions)
  }

  getImagemEmpresa(idEmpresa) {
    return this.http.get<Blob>(`${this.url}/imagembase64?empresa=${idEmpresa}`, this.httpOptions)
  }

  getEmpresa(idEmpresa): Observable<GenericResult<EmpresaV2Result[]>> {
    var idEmpresaConvertido = ("000" + idEmpresa).slice(-6)
    return this.http.get<GenericResult<EmpresaV2Result[]>>(`${this.url}/empresas?codigo=${idEmpresaConvertido}`, this.httpOptions)
  }

  getEmpresaDesc(desc: string, uf: string): Observable<GenericResult<EmpresaV2Result[]>> {
    return this.http.get<GenericResult<EmpresaV2Result[]>>(`${this.url}/empresas?uf=${uf}&?descr=${desc}`, this.httpOptions)
  }

  getIdEmpresa(caminho: string): Observable<GenericResult<EmpresaV2Result[]>> {
    return this.http.get<GenericResult<EmpresaV2Result[]>>(`${this.url}/empresas?nomeurl=${caminho}`, this.httpOptions)
  }

}
