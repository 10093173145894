import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HorarioService {

  constructor() { }

  getHorario() {
    var data = new Date()
    return data.getFullYear() + '-' + ("0" + (data.getMonth() + 1)).slice(-2) + '-' + ("0" + data.getDate()).slice(-2) + 'T' +
      ("0" + data.getHours()).slice(-2) + ':' + ("0" + data.getMinutes()).slice(-2) + ':' + ("0" + data.getSeconds()).slice(-2) + '.'
      + data.getMilliseconds()
  }
}
