import { Injectable } from "@angular/core";
import * as Cookies from "js-cookie";
import { HorarioService } from 'src/app/service/horario.service';
import { UsuarioLogadoService } from "../auth/usuario-logado.service";
import { CarrinhoService } from "../conexao/carrinho/carrinho.service";
import { GenericResult } from "../models/genericResult";
import { MessageService } from "./message.service";

@Injectable({
    providedIn: 'root'
})
export class PostCarrinhoCache {

    auxCarrinho = 0
    auxNaoExiste = 0

    constructor(
        private horario: HorarioService,
        private carrinhoService: CarrinhoService,
        private usuarioLogadoService: UsuarioLogadoService,
        private messageService: MessageService) { }


    run() {
        if (Cookies.get(`carrinho_${this.auxCarrinho}_ID`)) {
            var carrinho = {
                "CARRINHO": [
                    {
                        "EMPRESA": parseInt(Cookies.get(`carrinho_${this.auxCarrinho}_Empresa`)),
                        "IDLOGIN": this.usuarioLogadoService.id2,
                        "DATA": this.horario.getHorario(),
                        "ITENS": [
                            {
                                "PRODUTO": parseInt(Cookies.get(`carrinho_${this.auxCarrinho}_ID`)),
                                "VLRUNITARIO": parseFloat(Cookies.get(`carrinho_${this.auxCarrinho}_Valor`)),
                                "QUANTIDADE": parseInt(Cookies.get(`carrinho_${this.auxCarrinho}_Quantidade`)),
                                "VLRTOTAL": parseFloat(Cookies.get(`carrinho_${this.auxCarrinho}_Total`))
                            }
                        ]
                    }
                ]
            }
            this.carrinhoService.salvarCarrinho(carrinho).subscribe({
                next: value => this.onSuccessSalvarCarrinho(value),
                error: err => this.messageService.criaMensagem(err.error.MESSAGE)
            })
        } else if (this.auxNaoExiste <= 30) {
            this.auxNaoExiste++
            this.auxCarrinho++
            this.run()
        }
    }

    onSuccessSalvarCarrinho(value: GenericResult<any>): void {
        Cookies.remove(`carrinho_${this.auxCarrinho}_Empresa`)
        Cookies.remove(`carrinho_${this.auxCarrinho}_Foto`)
        Cookies.remove(`carrinho_${this.auxCarrinho}_ID`)
        Cookies.remove(`carrinho_${this.auxCarrinho}_Nome`)
        Cookies.remove(`carrinho_${this.auxCarrinho}_Quantidade`)
        Cookies.remove(`carrinho_${this.auxCarrinho}_Total`)
        Cookies.remove(`carrinho_${this.auxCarrinho}_Valor`)
        Cookies.remove(`carrinho_${this.auxCarrinho}_nomeEmpresa`)
        this.auxCarrinho++
        this.run()
    }
}