import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guards';
import { CarregamentoComponent } from './paginas/carregamento/carregamento.component';
import { EmpresasMTComponent } from './paginas/empresas-mt/empresas-mt.component';

const routes: Routes = [
  { path: '', component: EmpresasMTComponent, canActivate: [AuthGuard] },
  { path: 'v1/:empresa', loadChildren: () => import('./site.module').then(m => m.SiteModule) },
  { path: 'bem-vindo', component: CarregamentoComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
