import { HttpClient, HttpClientJsonpModule, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CarrinhoV2 } from 'src/app/models/carrinhoV2';
import { GenericResult } from 'src/app/models/genericResult';
import { environment } from 'src/environments/environment';
import { HeadersService } from '../headers.service';

@Injectable({
  providedIn: 'root'
})
export class CarrinhoService {
  
  variavelLoginThor = btoa(("thorinfo:NY1hIi6XQBavRyy"))

  httpOptions = {
    headers: new HttpHeaders()
  };

  url = environment.url

  constructor(private http: HttpClient, private headers: HeadersService) {
    this.httpOptions.headers = this.headers.httpOptions.headers
  }

  getCarrinho(idCliente: any): Observable<GenericResult<CarrinhoV2[]>>{
    return this.http.get<GenericResult<CarrinhoV2[]>>(`${this.url}/carrinho?cliente=${idCliente}`, this.httpOptions)
  }

  salvarCarrinho(carrinho: any): Observable<GenericResult<any>>{
    return this.http.post<GenericResult<any>>(`${this.url}/carrinho`, carrinho, this.httpOptions)
  }

  editarCarrinho(carrinho){
    return this.http.put(`${this.url}/carrinho`, carrinho, this.httpOptions)
  }

  deletarCarrinho(idCarrinho, sequencia){
    return this.http.request('DELETE', `${this.url}/carrinho`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Basic ${this.variavelLoginThor}`
      }),
      body: { 'IDCARRINHO': idCarrinho, 'SEQUENCIA': sequencia }
    });
  }
}
