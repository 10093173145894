import { Injectable } from '@angular/core';
import * as Cookies from 'js-cookie';
import { PostCarrinhoCache } from '../service/postCarrinhoCache';

@Injectable({
  providedIn: 'root'
})
export class UsuarioLogadoService {

  id2: number
  url_atual: any

  constructor() { }

  pegaUsuario(dados: any, mudapagina) {
    this.id2 = dados.DATA[0].ID
    Cookies.set('id', dados.DATA[0].ID.toString(), { expires: 3 })
    return mudapagina
  }

  usuariojalogado() {
    this.id2 = parseInt(Cookies.get('id'))
  }
}
