import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import * as Cookies from "js-cookie";
import { Observable } from "rxjs";
import { InterceptorRouteService } from "../service/interceptorRoute.service";

@Injectable()
export class AuthGuard implements CanActivate {

  id: Observable<number>
  auxUsuariologado: boolean = false
  tipoSite: string
  validou = false

  constructor(private interceptor: InterceptorRouteService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    if (this.tipoSite == undefined)
      this.tipoSite = route.params.empresa

    this.interceptor.recebeTipoSite(this.tipoSite)

    if(route.url.length > 0)
      this.interceptor.pegaOutraRota(route.url)

    if(route.routeConfig.path == 'produto/:id')
      Cookies.set('produto', `${route.params.id}`, { expires: 1 })


    if (this.tipoSite == undefined || this.tipoSite == 'all')
      return true
    else {
      if (this.validou === true) {
        if (route.url.length == 0 && (this.tipoSite != 'all' || this.tipoSite != undefined)) {
          this.interceptor.recebeRota(`grupos/${Cookies.get('empresa')}`)
          return false
        }
        if (route.url[0].path == 'empresas' || route.url[0].path == 'empresa' || route.url[0].path == 'produto' || route.url[0].path == 'grupo')
          return true
        else
          return true
      } else {
        this.router.navigate(['/bem-vindo'])
        this.validou = true
        return true
      }
    }
  }
}