import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Cookies from 'js-cookie';
import { UsuarioLogadoService } from './auth/usuario-logado.service';
import { InterceptorRouteService } from './service/interceptorRoute.service';
import { MessageService } from './service/message.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  usuario: number
  mensagemView: string = ''
  siteUnico: boolean = false

  constructor(
    private usuarioLogadoService: UsuarioLogadoService,
    private messageService: MessageService,
    private route: Router,
    private interceptor: InterceptorRouteService) {
    this.usuarioLogadoService.usuariojalogado()
    this.usuario = this.usuarioLogadoService.id2
    this.messageService.appComponent = this
  }

  ngOnInit(): void {
    //Cookies.remove('empresa')
    Cookies.remove('descProd')
    Cookies.remove('empresaAberta')
    Cookies.remove('grupo')
    Cookies.remove('produto')
    setTimeout(() => {
      this.siteUnico = this.interceptor.siteUnico
    }, 1000)
  }

  message(mensagem: string) {
    this.mensagemView = mensagem
    setTimeout(() => {
      var aux = document.getElementById('message') as HTMLElement
      aux.style.display = 'block'
      aux.style.opacity = '1'
      setTimeout(() => {
        aux.style.opacity = '0'
        aux.style.display = 'none'
      }, 4000)
    }, 500)

  }

  login() {
    this.avaliaRota('login')
    this.observa()
  }

  observa() {
    if (!this.usuarioLogadoService.id2) {
      setTimeout(() => {
        this.observa()
        this.usuario = this.usuarioLogadoService.id2
      }, 5000)
    }

  }

  logout() {
    Cookies.remove('id')
    this.usuario = null
    this.usuarioLogadoService.id2 = null
    this.avaliaRota('')
  }

  navigateCarrinho() {
    Cookies.set('urlApoio', `${window.location.href}`, { expires: 1 })
    this.avaliaRota('carrinho')
  }

  desejos() {
    if (Cookies.get('id')) {
      this.avaliaRota('desejos')
    } else {
      alert('Você precisa estar logado para acessar a pagina de Desejos.')
    }
  }

  pedido() {
    if (Cookies.get('id')) {
      this.avaliaRota('pedido')
    } else {
      alert('Você precisa estar logado para acessar a pagina de Pedidos.')
    }
  }

  validarRota() {
    if (this.interceptor.tipoSite == 'all' || this.interceptor.tipoSite == undefined)
      this.avaliaRota('')
    else
      this.avaliaRota(`grupos/${Cookies.get('empresa')}`)
  }

  avaliaRota(rota: string) {
    this.interceptor.recebeRota(rota)
  }

}
