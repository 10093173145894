import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CadastroSuccess } from 'src/app/models/cadastroSuccess';
import { EmailVerificado } from 'src/app/models/emailVerificadoV2';
import { GenericResult } from 'src/app/models/genericResult';
import { LoginSuccess } from 'src/app/models/loginSuccess';
import { environment } from 'src/environments/environment';
import { HeadersService } from '../headers.service';
import { Cadastro } from './cadastro';
import { Login } from './login';
import { Resend } from './resend';
import { Reset } from './reset';
import { Verificacao } from './verificacao';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  
  chaveBancoThor = 'VEhPUg=='
  url = environment.url

  httpOptions = {
    headers: new HttpHeaders({})
  };

  constructor(private http: HttpClient, private headers: HeadersService) {
    this.httpOptions.headers = this.headers.httpOptions.headers
    this.httpOptions.headers = this.headers.httpOptions.headers.append('dwaccesstag', this.chaveBancoThor)
  }

  login(login: Login): Observable<GenericResult<LoginSuccess>>{
    return this.http.post<GenericResult<LoginSuccess>>(`${this.url}/login?opr=LOGIN`, login, this.httpOptions);
  }

  cadastro(cadastro: Cadastro): Observable<GenericResult<CadastroSuccess>>{
    return this.http.post<GenericResult<CadastroSuccess>>(`${this.url}/login?opr=NEW`, cadastro, this.httpOptions);
  }

  verificaEmail(verificacao: Verificacao): Observable<GenericResult<EmailVerificado>>{
    return this.http.post<GenericResult<EmailVerificado>>(`${this.url}/login?opr=CONFIRM`, verificacao, this.httpOptions);
  }

  resend(email: Resend): Observable<GenericResult<any>>{
    return this.http.post<GenericResult<any>>(`${this.url}/login?opr=RESEND`, email, this.httpOptions);
  }

  reset(atualizacao: Reset): Observable<GenericResult<any>>{
    return this.http.post<GenericResult<any>>(`${this.url}/login?opr=RESET`, atualizacao, this.httpOptions);
  }
}
