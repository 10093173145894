import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Cookies from 'js-cookie';
import { EmpresaService } from 'src/app/conexao/empresas/empresa.service';
import { EmpresaV2Result } from 'src/app/models/empresasV2Result';
import { GenericResult } from 'src/app/models/genericResult';
import { ProdutosFirstPage } from 'src/app/models/produtosFirstPage';
import { InterceptorRouteService } from 'src/app/service/interceptorRoute.service';
import { MessageService } from 'src/app/service/message.service';

@Component({
  selector: 'app-empresas-mt',
  templateUrl: './empresas-mt.component.html',
  styleUrls: ['./empresas-mt.component.css']
})
export class EmpresasMTComponent implements OnInit {

  produtos: ProdutosFirstPage[] = [];
  empresas: EmpresaV2Result[] = [];
  carregaPaginaVar = false;
  empresaNaoEncontrada = true;
  filtroEmpresas: string = '';
  reader: any;
  ultimaEmpresa: number;
  buscaProd = '';

  constructor(
    private location: Location,
     private empresaService: EmpresaService, 
     private route: Router, 
     private messageService: MessageService,
     private interceptor: InterceptorRouteService) { }

  ngOnInit(): void {
    this.getAllEmpresas();
  }

  voltaPagina() {
    this.location.back();
  }

  getAllEmpresas() {
    this.empresaService.getAllEmpresasFirstPage().subscribe({
      next: value => this.onSuccessEmpresas(value),
      error: err => this.messageService.criaMensagem(err.error.MESSAGE)
    })
  }

  onSuccessEmpresas(value: GenericResult<ProdutosFirstPage[]>): void {
    if (value.SUCCESS === true) {
      this.produtos = value.DATA
      for (var i = 0; i < this.produtos.length; i++) {
        for (var j = 0; j < this.produtos[i].PRODUTOS.length; j++) {
          if (this.produtos[i].PRODUTOS[j].URLIMG == '') {
            this.produtos[i].PRODUTOS[j].URLIMG = 'assets/produto-sem-imagem.png'
          }
        }
      }
      this.ultimaEmpresa = this.produtos[this.produtos.length - 1].EMPRESA
      this.carregaPaginaVar = true;
    } else {
      this.empresaNaoEncontrada = false;
      this.carregaPaginaVar = true;
    }
  }

  selecionarEmpresa(empresa: any) {
    Cookies.set('empresa', empresa.EMPRESA.toString(), { expires: 3 })
    Cookies.set('empresaAberta', empresa.FANTASIA, { expires: 3 })
    Cookies.set('atacadoEmpAberta', empresa.QTD_ITENS_ATACADO_GERAL, { expires: 3 })
    this.avaliaRota(`grupos/${empresa.EMPRESA}`)
  }

  selecionarProduto(idProduto: number, idEmpresa: number, empresa: string) {
    Cookies.set('empresa', idEmpresa.toString(), { expires: 3 })
    Cookies.set('produto', idProduto.toString(), { expires: 3 })
    Cookies.set('empresaAberta', empresa, { expires: 3 })
    Cookies.set('urlApoio', `/`, { expires: 1 })
    this.avaliaRota(`produto/${idProduto}`)
  }

  busca() {
    Cookies.set('UF', 'MT', { expires: 1 })
    Cookies.set('desc', this.filtroEmpresas, { expires: 1 })
    this.avaliaRota('empresas')
  }

  onSuccessDesc(value: any[]): void {
    if (value.length != 0) {
      this.empresas = value;
      this.carregaPaginaVar = true;
    } else {
      this.empresaNaoEncontrada = false;
      this.carregaPaginaVar = true;
    }
  }

  buscaProduto() {
    if (this.buscaProd != '') {
      Cookies.set('descProd', this.buscaProd, { expires: 1 })
      this.avaliaRota('produtos')
    }
  }

  avaliaRota(rota: string){
    this.interceptor.recebeRota(rota)
  }

}
