<div class="div-principal">
    <div class="voltar" id="titulo">
        <a id="text">LOGIN</a>
    </div>
    <div id="div-form">
        <a id="facaLogin">Faça login para ter acesso a milhares de produtos!</a>
        <div id="divisao-contato"></div>
        <form class="row g-3" #f="ngForm" (ngSubmit)="onSubmit()">
            <div class="col-md-6">
                <label for="email" class="form-label">Email</label>
                <input type="email" class="form-control" id="email1" name="email" [(ngModel)]="login.email" required
                    #email="ngModel">
                <div class="alerta" *ngIf="!email.valid && email.touched">
                    Email é obrigatorio!
                </div>
            </div>
            <div class="col-md-6">
                <label for="senha4" class="form-label">Senha</label>
                <input type="password" class="form-control" id="senha1" name="senha" [(ngModel)]="login.senha" required
                    #senha="ngModel">
                <div class="alerta" *ngIf="!senha.valid && senha.touched">
                    Senha é obrigatoria!
                </div>
            </div>
            <div id="buttons">
                <button class="btn btn-primary" type="submit"
                    [disabled]="!f.valid">Login</button>
                <a>ou <a (click)="avaliaRota('atualiza-cadastro')">Esqueci a senha</a></a><br>
                <a>Não tem uma conta? <a (click)="avaliaRota('cadastro')">Inscreva-se</a></a>
            </div>
        </form>
    </div>
</div>