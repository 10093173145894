import { Injectable } from "@angular/core";
import { Router, UrlSegment } from "@angular/router";
import * as Cookies from "js-cookie";
import { EmpresaService } from "../conexao/empresas/empresa.service";
import { MessageService } from "./message.service";

@Injectable({
    providedIn: 'root'
})
export class InterceptorRouteService {

    tipoSite: string
    siteUnico: boolean = false
    siteErrado: boolean = false
    outraRota: string

    constructor(private router: Router, private empresaService: EmpresaService, private messageService: MessageService) { }

    recebeRota(rota: string) {
        if (this.siteErrado == true)
            this.router.navigate([''])
        else {
            if (this.siteUnico == false)
                if (rota == '')
                    this.router.navigate([`${rota}`])
                else
                    this.router.navigate([`/v1/all/${rota}`])
            else {
                if (rota == '')
                    this.router.navigate([`/v1/${this.tipoSite}/grupos/${Cookies.get('empresa')}`])
                else
                    this.router.navigate([`/v1/${this.tipoSite}/${rota}`])
            }
        }
    }

    recebeTipoSite(tipoSite: string) {
        this.tipoSite = tipoSite
        if (this.tipoSite != 'all' && this.tipoSite != undefined) {
            this.siteUnico = true
        }
    }

    pegaOutraRota(rotas: UrlSegment[]) {
        this.outraRota = ''
        for(var i = 0; i < rotas.length; i++){
            this.outraRota +=  rotas[i].path
            if(i + 1  < rotas.length)
                this.outraRota += '/'
        }
        
    }
}