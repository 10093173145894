<div *ngIf="carregaPaginaVar != true" id="carregando">
    <img src="assets/pre-carregamento-prod.gif">
    <p>Carregando...</p>
</div>
<div class="div-principal" *ngIf="carregaPaginaVar != false">

    <div class="voltar">
        <a id="text">BEM VINDO</a>
    </div>
    <div class="div-empresas">
        <div class="input-group mb-3">
            <input type="text" class="form-control" id="buscaEmpresa" placeholder="Procure uma Empresa..."
                aria-describedby="button-addon2" [(ngModel)]="filtroEmpresas">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="busca()">Buscar</button>
        </div>
        <div class="input-group mb-3">
            <input type="text" class="form-control" id="buscaEmpresa" placeholder="Procure um Produto..."
                aria-describedby="button-addon2" [(ngModel)]="buscaProd">
            <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="buscaProduto()">Buscar</button>
        </div>
        <div class="empresa" *ngFor="let empresa of produtos">
            <h5 id="nomeEmpresa">{{ empresa.FANTASIA }}</h5>
            <label for="form-label" *ngIf="empresa.QTD_ITENS_ATACADO_GERAL > 0" id="atacado">Atacado a partir de {{ empresa.QTD_ITENS_ATACADO_GERAL }} unidades</label>
            <div class="card mb-3" *ngFor="let produto of empresa.PRODUTOS | filter: filtroProdutos"
                (click)="selecionarProduto(produto.PRODUTO, empresa.EMPRESA, empresa.FANTASIA)">
                <div class="row g-0">
                    <div class="col-4">
                        <img src="{{ produto.URLIMG }}" class="img-fluid rounded-start" alt="..." id="fotoCard">
                    </div>
                    <div class="col-8">
                        <div class="card-body">
                            <h5 class="card-title">{{ produto.NOME }}</h5>
                            <p class="card-text"><span id="rs">R$ {{ produto.PRECOVENDA_TELA | number : "1.2"}}</span></p>
                        </div>
                    </div>
                </div>
                <span id="codProd">Cod. {{produto.PRODUTO}}</span>
            </div>
            <div *ngIf="empresaNaoEncontrada != true" id="empresaNãoEncontrada">
                <div class="alert alert-primary d-flex align-items-center" role="alert">
                    <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:">
                        <use xlink:href="#info-fill" />
                    </svg>
                    <div>
                        Nenhum produtos encontrado
                    </div>
                </div>
            </div>
            <p id="verMais" (click)="selecionarEmpresa(empresa)">Ver Mais</p>
            <div class="barrinha" *ngIf="ultimaEmpresa != empresa.EMPRESA"></div>
        </div>
    </div>
</div>


<svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
    <symbol id="info-fill" fill="currentColor" viewBox="0 0 16 16">
        <path
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
    </symbol>
</svg>