import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as Cookies from 'js-cookie';
import { UsuarioLogadoService } from 'src/app/auth/usuario-logado.service';
import { Login } from 'src/app/conexao/login/login';
import { LoginService } from 'src/app/conexao/login/login.service';
import { GenericResult } from 'src/app/models/genericResult';
import { LoginSuccess } from 'src/app/models/loginSuccess';
import { InterceptorRouteService } from 'src/app/service/interceptorRoute.service';
import { MessageService } from 'src/app/service/message.service';
import { PostCarrinhoCache } from 'src/app/service/postCarrinhoCache';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  login: Login

  constructor(
    private route: Router,
    private loginService: LoginService,
    private usuariologadoService: UsuarioLogadoService,
    private messageService: MessageService,
    private postCarrinhoCache: PostCarrinhoCache,
    private interceptor: InterceptorRouteService) { }

  ngOnInit(): void {
    window.scrollTo(0, 0)
    this.login = new Login()
  }

  onSubmit() {
    this.loginService.login(this.login).subscribe({
      next: value => this.onSuccessLogin(value),
      error: err => this.messageService.criaMensagem(err.error.MESSAGE)
    })
  }

  onSuccessLogin(value: GenericResult<LoginSuccess>): void {
    if (value.DATA[0].ID)
      this.usuariologadoService.pegaUsuario(value, this.mudaPagina())
  }


  mudaPagina() {
    setInterval(() => {
      this.postCarrinhoCache.run()
    }, 500)
    if (Cookies.get('ultimaUrl')) {
      this.route.navigate([`${Cookies.get('ultimaUrl')}`])
      Cookies.remove('ultimaUrl')
    } else {
      this.avaliaRota('')
    }
  }

  avaliaRota(rota: string){
    this.interceptor.recebeRota(rota)
  }

}
