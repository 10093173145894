import { Component, OnInit } from '@angular/core';
import * as Cookies from 'js-cookie';
import { EmpresaService } from 'src/app/conexao/empresas/empresa.service';
import { InterceptorRouteService } from 'src/app/service/interceptorRoute.service';
import { MessageService } from 'src/app/service/message.service';

@Component({
  selector: 'app-carregamento',
  templateUrl: './carregamento.component.html',
  styleUrls: ['./carregamento.component.css']
})
export class CarregamentoComponent implements OnInit {

  constructor(private interceptor: InterceptorRouteService, private empresaService: EmpresaService, private messageService: MessageService) { }

  ngOnInit(): void {
    if(this.interceptor.outraRota == '' || this.interceptor.outraRota == undefined)
      this.load(this.interceptor.tipoSite)
    else
      this.loadRotaEspecifica(this.interceptor.outraRota, this.interceptor.tipoSite)
  }

  load(tipoSite: string) {
    if (tipoSite != undefined) {
      this.empresaService.getIdEmpresa(tipoSite).subscribe({
        next: value => {
          if (value.DATA.length > 0) {
            Cookies.set('empresa', value.DATA[0].CODIGO.toString(), { expires: 1 })
            this.interceptor.recebeRota('')
          } else
          window.location.href = '/'
        },
        error: err => {
          this.messageService.criaMensagem(err.error.MESSAGE)
        }
      })
    }
  }

  loadRotaEspecifica( outraRota: string, tipoSite: string) {
    this.empresaService.getIdEmpresa(tipoSite).subscribe({
      next: value => {
        if (value.DATA.length > 0) {
          Cookies.set('empresa', value.DATA[0].CODIGO.toString(), { expires: 1 })
          this.interceptor.recebeRota(outraRota)
        } else
        window.location.href = '/'
      },
      error: err => {
        this.messageService.criaMensagem(err.error.MESSAGE)
      }
    })
  }
}
