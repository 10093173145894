import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HeadersService {

  variavelLoginThor = btoa(("thorinfo:NY1hIi6XQBavRyy"))

  constructor() { 
  }

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Basic ${this.variavelLoginThor}`,
    })
  };
}
