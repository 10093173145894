import { Injectable } from "@angular/core";
import { AppComponent } from "../app.component";
import { UsuarioLogadoService } from "../auth/usuario-logado.service";

@Injectable({
    providedIn: 'root'
})
export class MessageService {

    appComponent: AppComponent

    constructor() { }

    criaMensagem(mensagem: string) {
        this.appComponent.message(mensagem)
    }

}